import React, { useEffect, useState } from 'react';
import PaystackPop from '@paystack/inline-js';
import ClipLoader from "react-spinners/ClipLoader";



const PaymentPage = () => {

  const queryParams = new URLSearchParams(window.location.search);
  const ref = queryParams.get('ref')

  let [loading, setLoading] = useState(true);
  let [error, setError] = useState("");


useEffect(() => {

    fetch(`https://carbin.ricive.com/api/v1/get-order/${ref}`)
    .then(response => response.json())
    .then(result => {
      if(result.errors){
      
      setError(result.message)
      setLoading(false)
      }else{
        console.log(result);
        let handler = PaystackPop.setup({
          key: 'pk_live_9df72b19c03350d8767bc78e6e109ebb5e34b52d', 
          reference: result.reference,
          email:result.email,
          amount: `${Number(result.amount) *100}`, 
          onClose: function(){
            setError("Payment window closed")
            setLoading(false)
          },
          callback: function(response){
          
            setError("Payment completed")
            setLoading(false)
         
          }
        });
      
        handler.openIframe();

      }
       
    })
    .catch(error => {
  
      setLoading(false)
    });

  




  }, []);

  return (
    <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:"100vh"}}>
      {
        loading && (
          <ClipLoader speedMultiplier={1.2} color="#36d7b7" />
        )
      }

      {
        error && (
          <p style={{color:'#667085'}}>{error}</p>
        )
      }
     
  
     
    </div>
  );
};

export default PaymentPage;


